import React, { useState } from 'react'
import Table from '../../../../components/DataTable/Table'
import PlanterGroup from '../popupView/PlanterGroup'
import { useNavigate } from 'react-router-dom'

function PlanterList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, drawer, setNeedLoad } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [records, setRecords] = useState(null)
    const navigate = useNavigate()

    const headCells = [
        { id: 'site', label: 'Site' },
        { id: 'land_id', label: 'ID parcelle' },
        { id: 'fkt', label: 'Fokontany' },
        { id: 'planter_number', label: 'Nombre planteur', type: 'Integer' },
        {
            id: 'group_number',
            label: 'Nombre de groupe',
            type: 'actions',
            actionButton: true,
            actions: (item) => {
                setOpenPopup(true)
                setRecords(item)
            }
        },
        // { id: 'species_planted', label: 'Espèce plantées'},
        // { id: 'planted_area', label: 'Superficie plantée(m²)', type: 'Integer' },
        {
            id: 'actions',
            type: 'actions',
            label: 'Actions',
            disableSorting: true,
            showEdit: false,
            showRemove: true,

            showView: '/app/mangrove/planter/view'
        }
    ]

    const planterChoosenAction = (code) => {
        navigate(`/app/mangrove/planter/information/${code}`)
        setOpenPopup(false)
    }
    return (
        <>
            <Table
                title="Information sur les planteurs"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={true}
            />
            {records && <PlanterGroup records={records} setRecords={setRecords} openPopup={openPopup} setOpenPopup={setOpenPopup} actions={(code) => planterChoosenAction(code)} />}
        </>
    )
}

export default PlanterList
