import { Chip, Container, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'

function ContentListPlanter(props) {
    const { onClick, planterContent, actions, presenceContent,isGroupManagement } = props

    return (
        <>
            {planterContent &&
                planterContent.map((item, index) => {
                    const presence = presenceContent.find((p) => p.planter_id === item.id)
                    const isPresent = presence ? presence.isPresent : false

                    return (
                        <Paper
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '45px',
                                borderRadius: 'inherit',
                                justifyContent: 'space-between',
                                backgroundColor: (index + 1) % 2 === 1 ? '#f5f3f0' : '#e4e4e4',
                                '& .MuiPaper-root': { backgroundColor: 'none' },
                                padding: '0px 10px 0px 10px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', height: '45px' }}>
                                <Chip label={`${index + 1}`} sx={{ margin: '0px 10px 0px 10px' }} />
                                <Typography
                                    key={index}
                                    variant="p"
                                    component="div"
                                    sx={{
                                        borderRadius: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    {[item?.firstname, ' ', item?.lastname ? item?.lastname[0] : '', item?.lastname ? '.' : ''].join('')}
                                </Typography>
                            </div>
                            {!actions && (
                                <Typography
                                    key={index}
                                    variant="p"
                                    component="div"
                                    sx={{
                                        color: 'red',
                                        borderRadius: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    {!isPresent && <i>absent</i>}
                                </Typography>
                            )}
                            {actions && !isGroupManagement && (
                                <VisibilityIcon
                                    fontSize="small"
                                    position="end"
                                    color="disabled"
                                    sx={{ ':hover': { cursor: 'pointer' } }}
                                    onClick={() => onClick(item.code)}
                                />
                            )}
                        </Paper>
                    )
                })}
        </>
    )
}

export default ContentListPlanter
