import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Paper, TableBody, TableRow, TableCell, Chip, Tooltip } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ReactComponent as IconQuadrillage } from '../../assets/icons/quadrillage.svg'

import useTable from '../../applications/hooks/UseTable'
import Controls from '../Entry/Controls'
import Popup from '../../layouts/Popup'
import Drawer from '../../layouts/Drawer'
import ConfirmDialog from '../../layouts/ConfirmDialog'
import Notification from '../../layouts/Notification'
import Toolbar from './Toolbar'
import { styled } from '@mui/material/styles'
import Loader from '../Entry/Loading'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { numStr } from '../../applications/UtilFunctions'
import { GlobalContext } from '../../applications/hooks/UseContext'
import { useAuth } from '../../applications/hooks/UseAuth'
import { FaTrashRestore } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        //margin: 10,
        padding: `${theme.spacing(3)} 0 0`,
        backgroundColor: '#F2F8F6'
    },
    cellClass: {
        textAlign: 'center'
    },
    filter: {
        '& .MuiInputBase-input': {
            height: 10,
            width: 50
        }
    }
}))

const TablePaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 5px 5px -3px #d2d2d2,0px 8px 10px 1px #d2d2d2,0px 3px 14px 2px #d2d2d2'
}))

function Table(props) {
    const classes = useStyles()
    const { user } = useAuth()
    const {
        filterParams,
        headCells,
        service,
        Form,
        drawer,
        title,
        haveAccessTo,
        currentPage,
        needLoad,
        setNeedLoad,
        hideFilterButton,
        showSiteButton,
        showMangroveSiteButton,
        hideToolbar,
        filterFromList,
        activeCenterFromList,
        activeCenterComFromList,
        closeDrawer
    } = props
    const [filter, setFilter] = useState({})
    const [openPopup, setOpenPopup] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [codeItem, setCodeItem] = useState(null)
    const [records, setRecords] = useState([])
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items
        }
    })

    const globalContext = useContext(GlobalContext)

    const [showFilter, setShowFilter] = useState(true)
    const [filterBadge, setFilterBadge] = useState(true)
    const [loading, setLoading] = useState(false)

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    const { TblContainer, TblHead, TblFilter, TblPagination, recordsAfterPagingAndSorting } = useTable(
        records,
        headCells,
        filterFn
    )

    useEffect(() => {
        if (filterParams && needLoad) {
            console.log(filterParams)
            const getData = () => {
                setLoading(true)
                service.load()
                const result = service.getAll({ action_id: filterParams.id })
                result.then((res) => {
                    if (res) {
                        console.log(res)
                        setRecords(res?.data)
                        setLoading(false)
                    }
                })
            }
            getData()
            setNeedLoad(false)
        }
    }, [filterParams, needLoad])

    useEffect(() => {
        const getData = async () => {
            setLoading(true)

            let params = {}
            console.log(filterFromList)
            if (filterFromList) params = filterFromList
            else params = filter
            console.log(globalContext?.value?.activeCenter)
            console.log(globalContext?.value?.activeCenterCom)
            if (activeCenterFromList !== 'tct' || globalContext?.value?.activeCenter !== 'tct')
                params = { ...params, center: activeCenterFromList || globalContext?.value?.activeCenter }
            if (activeCenterComFromList !== '' || globalContext?.value?.activeCenterCom !== '')
                params = { ...params, com: activeCenterComFromList || globalContext?.value?.activeCenterCom }
            if (filterParams) params = { ...filterParams }
            const data = await service.getAll(params)
            if (data) {
                console.log(data)
                setRecords(data?.data)
            }
            setLoading(false)
        }

        if (needLoad && !filterParams) {
            getData()
            setNeedLoad(false)
        }
    }, [needLoad])

    useEffect(() => {
        const getData = async () => {
            setLoading(true)

            let params = {}
            if (filterFromList) params = filterFromList
            else params = filter
            console.log(globalContext?.value?.activeCenter)
            console.log(globalContext?.value?.activeCenterCom)
            if (globalContext?.value?.activeCenter !== 'tct')
                params = { ...params, center: globalContext?.value?.activeCenter }
            if (globalContext?.value?.activeCenterCom !== '')
                params = { ...params, com: globalContext?.value?.activeCenterCom }
            if (filterParams) params = { ...filterParams }
            console.log(params)
            const data = await service.getAll(params)
            if (data) {
                console.log(data)
                setRecords(data?.data)
            }
            setLoading(false)
        }
        if (globalContext) {
            getData()
            setNeedLoad(false)
        }
    }, [globalContext])

    const handleSearch = (e) => {
        let target = e.target
        setFilterFn({
            fn: (items) => {
                if (target.value === '') return items
                else {
                    const searchTerm = target.value.toLowerCase()
                    return items.filter((item) => {
                        for (let key in item) {
                            if (item[key] && item[key].toString().toLowerCase().includes(searchTerm)) {
                                return true
                            }
                        }
                        return false
                    })
                }
            }
        })
    }

    const handleFilter = () => {
        setShowFilter(!showFilter)
        setFilterBadge(!showFilter)
    }

    const tooglePopup = (item) => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    const toggleDrawer = (drawerOpen, item = null) => {
        item && setRecordForEdit(item)
        setOpenDrawer(drawerOpen)
    }

    const onDelete = async (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        const result = await service.delete(id)
        setNeedLoad(true)
        if (result === 'undefined') new Notification(service.messages.delete_error, 0)
        else new Notification(service.messages.delete_success, 1)
    }

    const navigate = useNavigate()

    const addOrEdit = async (data, resetForm) => {
        try {
            if (data.id === 0) {
                const { status } = await service.create(data)
                new Notification(service.messages.create_success, 1)
                if (status.code === 200 || status.code === 201) {
                    new Notification(service.messages.update_success, 1)
                }
            } else {
                const { status } = await service.update(data.id, data)
                if (status.code === 200 || status.code === 201) {
                    new Notification(service.messages.update_success, 1)
                }
            }
            resetForm()
            setRecordForEdit(null)
            drawer ? setOpenDrawer(false) : setOpenPopup(false)
            setNeedLoad(true)
        } catch (error) {
            console.error('Error during operation:', error)
            // Afficher l'erreur dans la console
        }
    }

    const onRestore = async (item) => {
        service?.load()
        setNeedLoad(true)
        const result = await service?.restore(item)
        if (result) {
            console.log(result)
            new Notification(service.messages.restore_success, 1)
        }
    }

    const formatData = (data, dataType, headCell, format) => {
        console.log(data, dataType)
        if (!data) return data

        if (dataType === 'date') {
            if (format) {
                return moment(data).format(format)
            }
            return moment(data).format('DD/MM/YYYY')
        }

        if (dataType === 'species') {
            return <i>{data}</i>
        }
        if (typeof data === 'object') {
            const column = headCell?.cell
            if (typeof data[column] === 'object') {
                const value = headCell?.data
                return data[column][value]
            }
            return data[column]
        }

        if (dataType === 'year') {
            const date = new Date(data)
            const year = date.getFullYear()
            return year
        }

        if (dataType === 'photo') {
            return (
                <>
                    <Controls.CustomImageAvatar title={'Photo'} place="left" size={80} src={`${data}`} />
                </>
            )
        }

        if (dataType === 'Integer') {
            // Add thousand separators with a decimal point
            let number = parseInt(data)
            let separator = '.'
            let value = numStr(number, separator)
            return value
        }
        if (dataType === 'Integer_ha') {
            // Convert to hectares and add thousand separators
            let number = Number(data)
            number = number / 10000
            return number.toLocaleString('fr-FR', { maximumFractionDigits: 2 })
        } else {
            return data
        }
    }

    return (
        <>
            <Paper className={classes.pageContent} elevation={0} style={{ backgroundColor: '#F2F8F6' }}>
                {!hideToolbar && (
                    <Toolbar
                        handleSearch={handleSearch}
                        handleFilter={handleFilter}
                        filterBadge={filterBadge}
                        hideFilterButton={hideFilterButton}
                        showSiteButton={showSiteButton}
                        showMangroveSiteButton={showMangroveSiteButton}
                        setNeedLoad={setNeedLoad}
                        filter={filter}
                        setFilter={setFilter}
                    />
                )}
                <TablePaper style={{ padding: 10, borderRadius: 10 }} elevation={8}>
                    <TblContainer>
                        <TblHead classes={classes} />
                        {!filterBadge && <TblFilter classes={classes} />}
                        <TableBody>
                            {recordsAfterPagingAndSorting &&
                                recordsAfterPagingAndSorting()?.map((item) => (
                                    <TableRow key={item.id}>
                                        {headCells?.map((headCell, index) =>
                                            !headCell?.type?.includes('actions') ? (
                                                <TableCell
                                                    key={index}
                                                    className={classes.cellClass}
                                                    onClick={() => {
                                                        if (!headCell.link) {
                                                            const haveView = headCells.filter(
                                                                (cell) => cell.showView && !cell.link
                                                            )
                                                            if (haveView && !headCell.condition) {
                                                                console.log('have view : ', haveView)
                                                                console.log('item : ', item)
                                                                toggleDrawer(true, item)
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {headCell.link ? (
                                                        <Chip
                                                            onClick={() => {
                                                                if (headCell.showView) {
                                                                    navigate(
                                                                        `${headCell.showView.link}/${
                                                                            item[headCell.showView.param]
                                                                        }`,
                                                                        {
                                                                            state: {
                                                                                code: item[headCell.showView.param]
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                            label={formatData(item[headCell.id], headCell.type)}
                                                        />
                                                    ) : 'render' in headCell ? (
                                                        headCell.render(item)
                                                    ) : headCell.showGrid ? (
                                                        () => {
                                                            console.log(test)
                                                            return (
                                                                <>
                                                                    test
                                                                    {/* <QuadrillageButton/> */}
                                                                </>
                                                            )
                                                        }
                                                    ) : headCell.type === 'hover' ? (
                                                        <div>
                                                            <Tooltip
                                                                key={headCell.id}
                                                                title={headCell.hover(item)}
                                                                arrow
                                                                placement="right"
                                                                slotProps={{
                                                                    tooltip: {
                                                                        sx: {
                                                                            color: '#514E6A',
                                                                            backgroundColor: '#ffff',
                                                                            border: '1px solid #514E6A'
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <i
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: '#514E6A'
                                                                    }}
                                                                >
                                                                    <b>voir</b>
                                                                </i>
                                                            </Tooltip>
                                                        </div>
                                                    ) : (
                                                        formatData(
                                                            item[headCell.id],
                                                            headCell?.type,
                                                            headCell,
                                                            headCell?.format || null
                                                        )
                                                    )}
                                                    {headCell?.type === 'table' &&
                                                        item[headCell.id].length > 1 &&
                                                        item[headCell.id].join(', ')}
                                                    {headCell?.type === 'table' && item[headCell.id].length <= 0 && '-'}
                                                </TableCell>
                                            ) : (
                                                <TableCell className={classes.cellClass} key={index}>
                                                    {item?.system ||
                                                        (headCell.showEdit && headCell.editPath ? (
                                                            <Controls.ActionButton
                                                                disabled={!haveAccessTo(currentPage, 'edit')}
                                                                tooltip="Modifier"
                                                                color="primary"
                                                                onClick={() => {
                                                                    navigate(`${headCell.editPath}/${item.id}`)
                                                                }}
                                                            >
                                                                <EditOutlinedIcon fontSize="small" />
                                                            </Controls.ActionButton>
                                                        ) : (
                                                            headCell.showEdit && (
                                                                <Controls.ActionButton
                                                                    disabled={!haveAccessTo(currentPage, 'edit')}
                                                                    tooltip="Modifier"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        drawer
                                                                            ? toggleDrawer(true, item)
                                                                            : tooglePopup(item)
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon fontSize="small" />
                                                                </Controls.ActionButton>
                                                            )
                                                        ))}
                                                    {/* {item?.system ||
                                                        (headCell.showView && (
                                                            <Controls.ActionButton
                                                                tooltip="Voir"
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (!headCell.forcePassId) {
                                                                        navigate(
                                                                            `${headCell?.showView}/${
                                                                                item?.code || item?.id
                                                                            }`,
                                                                            {
                                                                                state: {
                                                                                    code: item.code || item?.id,
                                                                                    supplementData:
                                                                                        {
                                                                                            key: headCell?.supplementData,
                                                                                            value: item[
                                                                                                headCell?.supplementData
                                                                                            ]
                                                                                        } || null
                                                                                }
                                                                            }
                                                                        )
                                                                    } else {
                                                                        navigate(`${headCell?.showView}/${item?.id}`, {
                                                                            state: { code: item?.id }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <VisibilityIcon fontSize="small" />
                                                            </Controls.ActionButton>
                                                        ))} */}
                                                    {filterParams?.action_type === 'delete' && (
                                                        <>
                                                            {/* restore icon button */}
                                                            <Controls.ActionButton
                                                                tooltip="Restaurer"
                                                                color="primary"
                                                                onClick={() => {
                                                                    onRestore(item)
                                                                }}
                                                            >
                                                                <FaTrashRestore fontSize="small" />
                                                            </Controls.ActionButton>
                                                        </>
                                                    )}
                                                    {item?.system ||
                                                        (headCell.showRemove && (
                                                            <Controls.ActionButton
                                                                disabled={!haveAccessTo(currentPage, 'delete')}
                                                                tooltip="Supprimer"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        successMessage: 'Suppression en cours ...',
                                                                        title: 'Vous voulez vraiment le supprimer?',
                                                                        subTitle: 'Cette opération est irréversible.',
                                                                        onConfirm: () => {
                                                                            console.log(headCell)
                                                                            if (headCell.identifyer) {
                                                                                let params = new URLSearchParams({
                                                                                    id: item?.identifyer,
                                                                                    userStory: [
                                                                                        user.firstname,
                                                                                        user.lastname
                                                                                    ].join(' ')
                                                                                }).toString()
                                                                                onDelete(params)
                                                                            } else {
                                                                                if (headCell.recommandation) {
                                                                                    console.log(headCell.recommandation)
                                                                                    console.log(item)
                                                                                    const data = {}
                                                                                    headCell.recommandation.map(
                                                                                        (recommandation) => {
                                                                                            data[recommandation] =
                                                                                                item[recommandation]
                                                                                            return data
                                                                                        }
                                                                                    )
                                                                                    console.log(data)
                                                                                    let params = new URLSearchParams({
                                                                                        ...data,
                                                                                        userStory: [
                                                                                            user.firstname,
                                                                                            user.lastname
                                                                                        ].join(' ')
                                                                                    }).toString()
                                                                                    onDelete(params)
                                                                                } else {
                                                                                    let params = new URLSearchParams({
                                                                                        id: item.id,
                                                                                        userStory: [
                                                                                            user.firstname,
                                                                                            user.lastname
                                                                                        ].join(' ')
                                                                                    }).toString()
                                                                                    onDelete(params)
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </Controls.ActionButton>
                                                        ))}
                                                    {headCell.customButtons &&
                                                        headCell.customButtons.map((Item) => (
                                                            <Item id={item.id} {...item} />
                                                        ))}

                                                    {headCell?.type === 'concatenated' && (
                                                        <span>
                                                            {item.firstname} {item.lastname}
                                                        </span>
                                                    )}

                                                    {item?.system ||
                                                        (headCell.actionButton && (
                                                            <Chip
                                                                onClick={() => {
                                                                    headCell.actions(item)
                                                                }}
                                                                label={
                                                                    headCell.content
                                                                        ? headCell.content
                                                                        : item[headCell.id]
                                                                }
                                                                sx={{ borderRadius: 0 }}
                                                            />
                                                        ))}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            {recordsAfterPagingAndSorting().length === 0 && (
                                <>
                                    <td colSpan={40} style={{ textAlign: 'center' }}>
                                        Aucun résultat.
                                    </td>
                                </>
                            )}
                        </TableBody>
                    </TblContainer>
                    {recordsAfterPagingAndSorting().length !== 0 && <TblPagination />}
                </TablePaper>
            </Paper>
            {!drawer && (
                <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <Form recordForEdit={recordForEdit} addOrEdit={addOrEdit} setNeedLoad={setNeedLoad} />
                </Popup>
            )}
            {drawer && (
                <Drawer title={title} openDrawer={openDrawer} toggleDrawer={toggleDrawer} setNeedLoad={setNeedLoad}>
                    <Form
                        recordForEdit={recordForEdit}
                        addOrEdit={addOrEdit}
                        addReturnButton={false}
                        codeItem={codeItem}
                        activeCenterComFromList={activeCenterComFromList}
                        closeDrawer={closeDrawer}
                        setNeedLoad={setNeedLoad}
                    />
                </Drawer>
            )}
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            <Loader isLoading={loading} />
        </>
    )
}

export default Table

function QuadrillageButton(props) {
    const { id, grid } = props

    const handleOpenQuadrillage = () => {}

    return (
        <Controls.ActionButton
            tooltip="Ouvrir le polygone de quadrillage"
            color="primary"
            onClick={handleOpenQuadrillage}
        >
            <IconQuadrillage fontSize="small" />
        </Controls.ActionButton>
    )
}
