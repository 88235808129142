import React, { useState } from 'react'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import PlanterForm from './PlanterForm'

function PlanterAdd() {
    const { haveAccessTo } = useAuth()


    return (
        <>
            <ContentView
                title={`Enregistrement d'un planteur`}
                haveAccessTo={haveAccessTo}
                Form={PlanterForm}
            />
        </>
    )
}

export default PlanterAdd
