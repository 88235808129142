import React from 'react'
import { Paper, Card, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

// import Breadcrumbs from '@mui/material/Breadcrumbs'
// import Link from '@mui/material/Link'
// import Stack from '@mui/material/Stack'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F2F8F6',
        display: 'flex',
        justifyContent: 'space-between'
    },
    pageHeader: {
        padding: '0 0 5px',
        display: 'flex'
        //marginBottom: theme.spacing(2)
    },
    pageIcon: {
        display: 'inline-block',
        padding: theme.spacing(2),
        color: '#3c44b1'
    },
    pageTitle: {
        paddingLeft: 0,
        '& .MuiTypography-subtitle2': {
            opacity: '0.6'
        },
        '& .MuiTypography-h6': {
            fontWeight: '900',
            fontSize: '1.7em'
        }
    },
    breadcrumbs: {
        '& .MuiTypography-root': {
            fontSize: '0.9em',
            color: '#b2b3c2'
        }
    }
}))

export default function PageHeader(props) {
    const classes = useStyles()
    const { title, subTitle } = props

    // function handleClick(event) {
    //     event.preventDefault()
    // }

    /*     const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            Link
        </Link>,
        ,
        <Typography key="3" color="text.primary">
            Active link
        </Typography>
    ] */

    return (
        <>
            <Paper elevation={0} square className={classes.root} style={{ backgroundColor: '#F2F8F6' }}>
                <div className="flex gap-4">
                    <div className={classes.pageHeader}>
                        {/* <Card className={classes.pageIcon} style={{margin: 'auto 0'}}>
                    {icon}
                </Card> */}
                        <div className={classes.pageTitle}>
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                            {/* <Typography variant="subtitle2" component="div">
                            {subTitle}
                        </Typography> */}
                        </div>
                    </div>
                    <div className="mt-[4px]">{props.children}</div>
                </div>
            </Paper>
            {/* <Paper elevation={0} square className={classes.root}>
                <Stack spacing={2} className={classes.breadcrumbs}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Paper> */}
        </>
    )
}
