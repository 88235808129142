import React, { useState, useEffect, useContext } from 'react'
import PageHeader from '../../../../components/Typography/PageHeader'
import { makeStyles } from '@mui/styles'
import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import { useNavigate } from 'react-router-dom'
import ToolbarButton from '../../../../components/DataTable/ToolbarButton'
import AddNursery from './components/contentView/form/AddNursery'
import NurseryList from './components/listView/NurseryList'
import NurseryService from '../../services/Nursery'

import Controls from '../../../../components/Entry/Controls'
import NurseryManagement from './components/contentView/NurseryManagement'
import ProvinceFilter from '../../../provinces/components/ProvinceFilter'
import { GlobalContext } from '../../../../applications/hooks/UseContext'
import ConfirmDialog from '../../../../layouts/ConfirmDialog'
import Notification from '../../../../layouts/Notification'

const CURRENT_PAGE = 'agroforestry_pepiniere'
const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    },
    siteChange3: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#e8e4e4',
            borderWidth: 2,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-root': {
            minWidth: '10.9vw'
            //borderRadius: 10
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            background: '#F2F8F6',
            //borderRadius: 10,
            borderBottom: '3px solid #0FCB8F'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15
        }
    }
}))
function YoungPlant() {
    const classes = useStyles()
    const service = NurseryService.load()
    const { haveAccessTo, user } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true
    const globalContext = useContext(GlobalContext)

    const [filter, setFilter] = useState({})
    const [value, setValue] = useState('plant')

    // bloc centre
    const [centers, setCenters] = useState([])
    const [activeCenter, setActiveCenter] = useState('tct')
    const [activeCenterCom, setActiveCenterCom] = useState('')
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    // fin centre

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        navigate('/app/agroforestry/nursery/' + newValue)
    }

    useEffect(() => {
        console.log(globalContext)
        if (globalContext?.value?.activeCenter) {
            setActiveCenter(globalContext?.value?.activeCenter)
            setActiveCenterCom(globalContext?.value?.activeCenterCom)
        }
    }, [globalContext])
    useEffect(() => {
        console.log(globalContext)
        if (globalContext?.value?.activeCenterCom) {
            setActiveCenterCom(globalContext?.value?.activeCenterCom)
        }
    }, [globalContext])

    // DEBUT BLOC CENTRE
    useEffect(() => {
        if (activeCenter === 'com') {
            service.getAllNurseryFilter(filter, true, false).then((response) => {
                if (response) {
                    console.log(response)
                    setCenters(response.map((item) => ({ id: item.id, title: item.name })))
                }
            })
        }
        if (activeCenter === 'ct') {
            service.getAllNurseryFilter(filter, false, true).then((response) => {
                if (response) {
                    console.log(response)
                    setCenters(response.map((item) => ({ id: item.id, title: item.name })))
                }
            })
        } else if (activeCenter === 'tct') {
            service.getAllNurseryFilter(filter, false, false).then((response) => {
                if (response) {
                    console.log(response)
                    setCenters(response.map((item) => ({ id: item.id, title: item.name })))
                }
            })
        }
    }, [activeCenter, filter, service, activeCenterCom])

    useEffect(() => {
        if (filter) {
            setActiveCenter(globalContext?.value?.activeCenter)
        }
    }, [filter])

    const changeCenter = (event, newValue) => {
        if (newValue === '+') {
            event.preventDefault()
        } else {
            globalContext.setValue((prev) => {
                return {
                    ...prev,
                    activeCenter: newValue,
                    activeCenterCom: ''
                }
            })
            setActiveCenter(newValue)
            setActiveCenterCom('')
            setNeedLoad(true)
        }
    }

    const changeCenterCom = (event, newValue) => {
        setActiveCenterCom(newValue)
        globalContext?.setValue((prev) => {
            return {
                ...prev,
                activeCenterCom: newValue
            }
        })
        setNeedLoad(true)
    }

    const handleDeleteNursery = (id) => {
        let params = new URLSearchParams({
            id: id,
            userStory: [user.firstname, user.lastname].join(' ')
        }).toString()
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        const service = NurseryService.load()
        service
            .deleteNursery(params)
            .then((response) => {
                if (response) {
                    setNeedLoad(true)
                    console.log(response)
                    delete globalContext?.value?.activeCenterCom
                    setActiveCenterCom('')
                    new Notification(service.messages.delete_success, 1)
                    globalContext.setValue((prev) => {
                        return {
                            ...prev,
                            activeCenter: '',
                            activeCenterCom: ''
                        }
                    })
                    setActiveCenter('')
                }
            })
            .catch((error) => {
                console.log(error)
                new Notification(service.messages.delete_error, 0)
            })
    }

    const ListCenters = () => {
        return (
            <div className="flex flex-row gap-2" style={{ width: '300px', alignItems: 'center' }}>
                {/* <Controls.Select
                    label=""
                    name="centercom"
                    value={activeCenterCom || ''}
                    onChange={(e) => changeCenterCom(e, e.target.value)}
                    options={centers}
                /> */}
                <Controls.AutoCompleteSelect
                    useDeleteOption={true}
                    service={NurseryService.load()}
                    onDelete={(id) => {
                        setConfirmDialog({
                            isOpen: true,
                            successMessage: 'Suppression en cours ...',
                            title: 'Vous voulez vraiment le supprimer?',
                            subTitle: 'Cette opération est irréversible.',
                            onConfirm: () => {
                                handleDeleteNursery(id)
                            }
                        })
                    }}
                    label={`${
                        activeCenter === 'com'
                            ? 'Pépinières communautaires'
                            : activeCenter === 'ct'
                            ? 'Pépinières centrales'
                            : 'Pépinières'
                    }`}
                    name="centercom"
                    value={activeCenterCom || ''}
                    onChange={(e) => changeCenterCom(e, e.target.value)}
                    options={centers}
                    labelKey="title"
                    valueKey="id"
                />
            </div>
        )
    }
    // FIN BLOC : CENTRE

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader title={'Gestion pépinière'} subTitle={'Gestion pépinière'}>
                <ToolbarButton
                    moduleTitle="d'un espèce"
                    haveAccessTo={haveAccessTo}
                    label="Nouvelle pépinière"
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    isContentView={true}
                    link="/app/agroforestry/nursery/plant/new"
                    Form={AddNursery}
                />
            </PageHeader>
            <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
                <MuiToolbar style={{ padding: 0, alignItems: 'start' }} className={classes.siteChange}>
                    <BottomNavigation showLabels value={value} onChange={handleChange}>
                        <BottomNavigationAction key="1" label="Approvisionnement" value="supply" />
                        <BottomNavigationAction key="2" label="Production de jeunes plants" value="plant" />
                        <BottomNavigationAction key="3" label="Matériels & Outils" value="inventory" />
                    </BottomNavigation>
                </MuiToolbar>
            </div>
            <div className=" flex flex-row gap-4">
                <MuiToolbar style={{ padding: 0 }} className={classes.siteChange3}>
                    <BottomNavigation showLabels value={activeCenter} onChange={changeCenter}>
                        <BottomNavigationAction key={'0'} label="Tous" value="tct" />
                        <BottomNavigationAction key={'1'} label="Centrale" value="ct" />
                        <BottomNavigationAction key={'2'} label="Communautaire" value="com" />
                    </BottomNavigation>
                </MuiToolbar>
                {activeCenter && <ListCenters />}
            </div>

            <NurseryList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={NurseryManagement}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
                filterFromList={filter}
                activeCenterFromList={activeCenter}
                activeCenterComFromList={activeCenterCom}
            />
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    )
}

export default YoungPlant
