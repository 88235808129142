import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Popup from '../../layouts/Popup'

const useStyles = {
    avatar: {
        width: 100, // Taille de l'avatar en pixels
        height: 100
    },
    image: {
        objectFit: 'cover' // Pour s'assurer que l'image couvre toute la surface de l'avatar sans distorsion
    }
}

function CustomImageAvatar(props) {
    const { title, src, rounded, place, other, size, padding: margin, customStyle = {} } = props
    const uploadRoute = [process.env.MEDIA_URL, 'uploads'].join('/')
    const imageSrc = src ? src : `${uploadRoute}/default.jpg`
    const [imageExists, setImageExists] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)

    const handleClick = () => {
        setOpenPopup(true)
    }

    useEffect(() => {
        /*const checkImageExists = async () => {
            try {
                const response = await fetch(imageSrc);
                const contentType = response.headers.get('content-type');
                if (response.status === 200 && contentType && contentType.startsWith('image/')) {
                    setImageExists(true);
                } else {
                    setImageExists(false);
                }
            } catch (error) {
                console.error('Error checking image existence:', error);
                setImageExists(false);
            }
        };*/

        if (imageSrc) {
            //checkImageExists();
            setImageExists(true)
        }
    }, [imageSrc, uploadRoute])

    return (
        <>
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    borderRadius: rounded ? `${rounded}%` : '10%',
                    marginLeft: place === 'left' ? 0 : !place ? 'unset' : 'auto',
                    margin: margin,
                    ...customStyle
                }}
                imgProps={{
                    style: { objectFit: 'cover' },
                    srcSet: `${imageSrc} 1x, ${imageSrc} 2x`
                }}
                src={imageSrc}
                {...other}
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            />
            
            {openPopup && (
                <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <img src={imageSrc} alt={title} style={useStyles.image} />
                </Popup>
            )}
        </>
    )
}

export default CustomImageAvatar
