import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import { PlantMaterialForm } from './PlantMaterialForm'
import PlantMaterialService from '../../services/plant_material/plant_material'
import PlantMaterialContext from './utils/hooks/PlantMaterialContext'
import Notification from '../../../../layouts/Notification'

function PlantMaterialView(props) {
    const { addReturnButton, recordForEdit, activeCenterComFromList } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const needCode = false
    const dataId = location.pathname.split('/').pop()
    const inventoryService = PlantMaterialService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    //afficher recordForEdit dans la console avec useEffect
    return (
        <>
            <CssBaseline />
            <PlantMaterialContext
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                nurseryId={recordForEdit ? recordForEdit?.nursery_id : location.state.nurseryId}
                specieId={recordForEdit?.specieId}
                unity={recordForEdit?.unity}
            >
                <ContentView
                    needCode={needCode}
                    dataId={recordForEdit ? recordForEdit.id : location.state.nurseryId}
                    title={` Matière végétale`}
                    service={inventoryService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PlantMaterialForm}
                    addReturnButton={addReturnButton}
                    recordForEdit={recordForEdit}
                    needLoadCode={needLoadCode}
                />
            </PlantMaterialContext>
        </>
    )
}

export default PlantMaterialView
