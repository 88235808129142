import { Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import Controls from '../../../../components/Entry/Controls'
import { useForm } from '../../../../applications/hooks/UseForm'
import Subtitle from '../../../../components/Typography/Subtitle'
import Notification from '../../../../layouts/Notification'

const initialValues = {
    iD_points: '',
    leafNumber: '',
    height: '',
    isRefilled: false,
    lateralBranchesNumber: '',
    nodeNumber: '',
    diameter: '',
    crownLength: '',
    crownWidth: '',
    deadNumber: '',
    alivedNumber: '',
    number_refilled: ''
}

function TrackYoungPlant(props) {
    const { setPopupValues, setUserHaveToTrackYoungPlant, trackingPointList, species } = props
    const [dataList, setDataList] = useState([])
    const [trackingPointOption, setTrackingPointOption] = useState([])
    const [dataPoints, setDataPoints] = useState([])
    const [deadPlantsNumber, setDeadPlantsNumber] = useState(0)
    const refillData = [
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ]

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('iD_points' in fieldValues) temp.iD_points = fieldValues.iD_points ? '' : 'Ce champ est requis.'
        if ('alivedNumber' in fieldValues) temp.alivedNumber = fieldValues.alivedNumber ? '' : 'Ce champ est requis.'
        if ('deadNumber' in fieldValues) temp.deadNumber = fieldValues.deadNumber ? '' : 'Ce champ est requis.'
        if ('number_refilled' in fieldValues)
            temp.number_refilled = fieldValues.number_refilled ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    const addData = () => {
        console.log(dataList)
        console.log(values)
        if (
            values.specie_id &&
            values.leafNumber &&
            values.height &&
            values.lateralBranchesNumber &&
            values.nodeNumber &&
            values.diameter &&
            values.crownLength &&
            values.crownWidth
        ) {
            if (!dataList.find((item) => item.specie_id === values?.specie_id)) {
                setDataList([
                    ...dataList,
                    {
                        id: dataList[dataList.length - 1]?.id + 1 || 1,
                        specie_id: values.specie_id || '',
                        leafNumber: Number(values.leafNumber) || '0',
                        height: Number(values.height) || '0',
                        lateralBranchesNumber: Number(values.lateralBranchesNumber) || '0',
                        nodeNumber: Number(values.nodeNumber) || '0',
                        diameter: Number(values.diameter) || '0',
                        crownLength: Number(values.crownLength) || '0',
                        crownWidth: Number(values.crownWidth) || '0'
                    }
                ])
                setValues({
                    ...values,
                    leafNumber: '',
                    height: '',
                    lateralBranchesNumber: '',
                    nodeNumber: '',
                    diameter: '',
                    crownLength: '',
                    crownWidth: '',
                    specie_id: ''
                })
            } else {
                new Notification(`${species?.find((item) => item.id === values?.specie_id).label} déjà ajouter.`, 0)
            }
        } else {
            new Notification('Veuillez remplir les champs requis.', 0)
        }
    }

    const addDataPoint = () => {
        console.log(validate())
        if (validate()) {
            if (dataList.length > 0) {
                console.log(dataPoints.find((item) => item.id === values?.iD_points))
                if (!dataPoints.find((item) => item.id === values?.iD_points)) {
                    setDataPoints([
                        ...dataPoints,
                        {
                            id: values?.iD_points,
                            species: dataList,
                            plants_alived: parseInt(values?.alivedNumber) || 0,
                            plants_dead: parseInt(values?.deadNumber) || 0,
                            number_refilled: parseInt(values?.number_refilled) || 0
                        }
                    ])
                    resetForm()
                    setDataList([])
                } else {
                    new Notification(`Point de suivi ${values.iD_points} déjà suivi.`, 0)
                }
            } else {
                new Notification('Il faut au moins un espèce suivi.', 0)
            }
        } else {
            new Notification('Veuillez renseigner tous les champs obligatoires', 0)
        }
    }

    useEffect(() => {
        if (dataPoints) {
            console.log(dataPoints)
        }
    }, [dataPoints])

    useEffect(() => {
        if (trackingPointList) {
            setTrackingPointOption(trackingPointList)
        }
    }, [trackingPointList])

    const handleSubmit = () => {
        console.log(dataPoints)
        setPopupValues(dataPoints)
        setUserHaveToTrackYoungPlant(false)
    }

    const addDataDead = () => {
        setDeadPlantsNumber(deadPlantsNumber + 1)
    }

    return (
        <div style={{ width: '800px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Grid
                container
                style={{
                    padding: '20px',
                    border: '2px solid #ccc',
                    borderRadius: '5px'
                }}
            >
                <Controls.AutoCompleteSelect
                    label="ID point de suivi"
                    name="iD_points"
                    value={values?.iD_points}
                    error={errors?.iD_points}
                    onChange={handleInputChange}
                    options={trackingPointOption || []}
                    labelKey="iD_points"
                    valueKey="iD_points"
                    isRequired={true}
                />

                <Grid item xs={7}>
                    <div>
                        <Typography variant="p" component="div" style={{ marginBottom: '10px' }}>
                            <b>
                                <i>Jeune plant : {dataList.length + 1}</i>
                            </b>
                        </Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Espèce"
                                        name="specie_id"
                                        value={values?.specie_id}
                                        error={errors?.specie_id}
                                        onChange={handleInputChange}
                                        options={species || []}
                                        labelKey="label"
                                        valueKey="id"
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Nombre de noeuds"
                                        type="number"
                                        name="nodeNumber"
                                        value={values.nodeNumber}
                                        error={errors.nodeNumber}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Diamètre (cm)"
                                        type="number"
                                        name="diameter"
                                        value={values.diameter}
                                        error={errors.diameter}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Largeur de couronne"
                                        type="number"
                                        name="crownWidth"
                                        value={values.crownWidth}
                                        error={errors.crownWidth}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.Input
                                        label="Nombre de feuilles"
                                        type="number"
                                        name="leafNumber"
                                        value={values.leafNumber}
                                        error={errors.leafNumber}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Hauteur (cm)"
                                        type="number"
                                        name="height"
                                        error={errors.height}
                                        value={values.height}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Nombre de branches latérales"
                                        type="number"
                                        name="lateralBranchesNumber"
                                        value={values.lateralBranchesNumber}
                                        error={errors.lateralBranchesNumber}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label="Longueur de couronne"
                                        type="number"
                                        name="crownLength"
                                        value={values.crownLength}
                                        error={errors.crownLength}
                                        onChange={handleInputChange}
                                        isRequired={true}
                                    />
                                    {/* <Controls.RadioGroup
                                        name="isRefilled"
                                        label="Regarnie"
                                        items={refillData}
                                        value={values.isRefilled}
                                        onChange={handleInputChange}
                                    /> */}
                                </Container>
                            </Grid>
                        </Grid>
                        <Container>
                            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                <Controls.Button text="Ajouter" onClick={addData} />
                                {/* <Controls.Button text="Mort" onClick={addDataDead}  color='error' /> */}
                            </div>
                        </Container>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <Container>
                        <Paper style={{ padding: '10px', marginBottom: '10px' }}>
                            <Typography variant="p" component="div" style={{ marginBottom: '10px' }} fontSize={12}>
                                Jeunes plants suivis :
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    overflowY: 'scroll',
                                    height: '250px',
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: '#d0e0d7 #E7F2EE',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                        height: '8px',
                                        background: '#d0e0d7'
                                    }
                                }}
                            >
                                {dataList
                                    .map((data) => (
                                        <div
                                            key={data.id}
                                            style={{
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '7px'
                                            }}
                                        >
                                            <Typography
                                                variant="p"
                                                component="div"
                                                style={{ marginBottom: '10px', display: 'flex' }}
                                                fontSize={12}
                                            >
                                                <div
                                                    style={{
                                                        width: '90%',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <i>
                                                        {data.id} -{' '}
                                                        {species.find((s) => s.id === data.specie_id)?.label} -{' '}
                                                        {data.height} cm
                                                    </i>
                                                </div>
                                                <FaTrashAlt
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        alignSelf: 'center',
                                                        display: 'flex',
                                                        alignContent: 'flex-end'
                                                    }}
                                                    onClick={() =>
                                                        setDataList(dataList.filter((v) => v.id !== data.id))
                                                    }
                                                />
                                            </Typography>
                                        </div>
                                    ))
                                    .reverse()}
                            </div>
                            <Typography
                                variant="p"
                                component="div"
                                style={{ marginBottom: '10px' }}
                                fontSize={12}
                            ></Typography>
                        </Paper>
                    </Container>
                </Grid>
                <Controls.Input
                    label="Nombre de plants morts"
                    type="number"
                    name="deadNumber"
                    value={values.deadNumber}
                    error={errors.deadNumber}
                    onChange={handleInputChange}
                    isRequired={true}
                />
                <Controls.Input
                    label="Nombre de plants vivants"
                    type="number"
                    name="alivedNumber"
                    value={values.alivedNumber}
                    error={errors.alivedNumber}
                    onChange={handleInputChange}
                    isRequired={true}
                />
                <Controls.Input
                    label="Nombre regarnies"
                    type="number"
                    name="number_refilled"
                    value={values.number_refilled}
                    error={errors.number_refilled}
                    onChange={handleInputChange}
                    isRequired={true}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button text="Valider" size="large" onClick={addDataPoint} />
                </div>
            </Grid>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    backgroundColor: '#f1efef',
                    overflowY: 'scroll',
                    height: '250px',
                    border: '2px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#d0e0d7 #E7F2EE',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                        background: '#d0e0d7'
                    }
                }}
            >
                {dataPoints
                    .map((point) => {
                        return (
                            <div
                                key={point.id}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '7px',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography
                                    variant="p"
                                    component="div"
                                    style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}
                                    fontSize="0.6rem"
                                >
                                    <Subtitle
                                        style={{
                                            fontSize: '0.8rem',
                                            color: '#677788'
                                        }}
                                    >
                                        {point.id}
                                    </Subtitle>
                                    <div>
                                        Nombre moyenne de feuilles :
                                        <b>
                                            {point.species.length > 0
                                                ? (
                                                      point.species.reduce((a, b) => a + b.leafNumber, 0) /
                                                      point.species.length
                                                  ).toFixed(2)
                                                : 0}
                                        </b>
                                    </div>
                                    <div>
                                        Hauteur moyenne :
                                        <b>
                                            {point.species.length > 0
                                                ? (
                                                      point.species.reduce((a, b) => a + b.height, 0) /
                                                      point.species.length
                                                  ).toFixed(2)
                                                : 0}{' '}
                                            cm
                                        </b>
                                    </div>
                                    <div>
                                        Nombre de plants vivants : <b>{point.plants_alived}</b>
                                    </div>
                                    <div>
                                        Nombre de plants morts : <b>{point.plants_dead}</b>
                                    </div>
                                    <div>
                                        Nombre regarnies : <b>{point.number_refilled}</b>
                                    </div>
                                </Typography>
                                <FaTrashAlt
                                    style={{
                                        cursor: 'pointer',
                                        color: 'red',
                                        alignSelf: 'center',
                                        display: 'flex'
                                    }}
                                    onClick={() => setDataPoints(dataPoints.filter((v) => v.id !== point.id))}
                                />
                            </div>
                        )
                    })
                    .reverse()}
            </div>
            {/* <div className="flex justify-end items-end mt-4"> */}
            <Controls.Button text="Soumettre" onClick={handleSubmit} size="large" />
            {/* </div> */}
        </div>
    )
}

export default TrackYoungPlant
