import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Avatar } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import addImage from '../../../../../../assets/image/addPhoto.png'
import AddLandedStateFiles from '../popup/AddLandedStateFiles'

function LandInformations(props) {
    const { styles, disabledField, values, errors, handleInputChange, setValues, mangroClassDataAdd } = props
    const recipient_polygon = [
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ]
    const [valueImage, setValueImage] = useState([])
    const [openLandedState, setOpenLandedState] = useState(false)
    const [landed_state, setLanded_state] = useState([])

    const handleAddPhoto = (value) => {
        setValueImage([...valueImage, value])
        setValues({ ...values, landed_state: landed_state })
    }

    return (
        <>
            <Typography sx={styles.form_title}>Information terrains</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        {/* <Controls.Input
                            label="ID polygone de quadrillage"
                            name="land_grid"
                            isDisabled={disabledField}
                            value={values.land_grid}
                            error={errors.land_grid}
                            onChange={handleInputChange}
                        /> */}
                        <Controls.AutoCompleteSelect
                            label="Classe de mangroves"
                            name="mangrove_class"
                            options={mangroClassDataAdd || []}
                            labelKey="label"
                            valueKey="label"
                            onChange={handleInputChange}
                            value={values.mangrove_class}
                            error={errors.mangrove_class}
                            isRequired={true}
                        />
                        <Controls.RadioGroup
                            name="is_recipient_polygon"
                            label="polygone d'étude d'état initial"
                            items={recipient_polygon}
                            isDisabled={disabledField}
                            value={values.is_recipient_polygon}
                            onChange={handleInputChange}
                        />
                        {/* <Controls.Input
                            label="Classe de mangroves"
                            name="mangrove_class"
                            isDisabled={disabledField}
                            value={values.mangrove_class}
                            error={errors.mangrove_class}
                            onChange={handleInputChange}
                        /> */}
                        {/* <Controls.MultipleFileInput
                            label="Statut foncier"
                            onChange={handleInputChange}
                            name="landed_state"
                            setValues={setLanded_state}
                        /> */}
                        {/* <Controls.DynamicTextInput
                            label="Statut foncier"
                            placeholder={''}
                            customStyle={{
                                height: '100%',
                                marginBottom: '10px',
                                '& textarea': {
                                    width: '80%'
                                }
                            }}
                            value={valueImage?.map((item, index) => `fichier ${index + 1}`).join(', ') || ''}
                            isDisabled={valueImage?.length > 0 ? false : true}
                            haveIcon={valueImage?.length > 0 ? true : false}
                            onClick={() => {
                                setOpenLandedState(true)
                            }}
                        />
                        <Paper
                            sx={{
                                display: 'inline-block'
                            }}
                        >
                            <Controls.ImageInput
                                error={errors.photos}
                                value={values.photos}
                                name="photos"
                                onChange={(e) => {
                                    handleAddPhoto(e.target.content)
                                }}
                                customizedButton={true}
                                setValueImage={setValueImage}
                            >
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        //add backgroud color
                                        backgroundColor: '#f1f1f1',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f1f1f1'
                                        }
                                    }}
                                    className={styles.addPhoto}
                                    //get image src from assets
                                    src={addImage}
                                    //ajouter une alt quand le bouton est survolé par le pointer de la souris
                                    alt="Ajouter une photo"
                                />
                            </Controls.ImageInput>
                            
                        </Paper> */}
                        {/* <Controls.Button text="Ajouter" size={'small'} /> */}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input
                            label="Surface (ha)"
                            name="surface"
                            isDisabled={disabledField}
                            value={values.surface}
                            error={errors.surface}
                            onChange={handleInputChange}
                            type="number"
                        />
                    </Paper>
                </Grid>
            </Grid>
            {valueImage && (
                <AddLandedStateFiles
                    openPopup={openLandedState}
                    setOpenPopup={setOpenLandedState}
                    fileData={valueImage}
                />
            )}
        </>
    )
}

export default LandInformations
