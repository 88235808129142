import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import { useCallback, useEffect, useState } from "react";
import ErrorHandler from "../../applications/Error";
import Controls from "../../components/Entry/Controls";
import PageHeader from "../../components/Typography/PageHeader";
import Notification from "../../layouts/Notification";
import Config from "./components/GlobalConfig";
import ConfigEmailService from "./services/globalConfig";
export default function Configuration() {
  const service = ConfigEmailService.load();
  const [globalData, setGlobalData] = useState(null);
  async function valideEmailConfig() {
    try {
      const newConfig = globalData?.map((d) => {
        return {
          ...d,
          data: JSON.stringify(d.data),
        };
      });
      const res = await service.updateConfig(newConfig);
      if (res?.status.code === 200) {
        new Notification(service.messages.update_success, 1);
      }
    } catch (err) {
      ErrorHandler.handle(err);
    }
  }
  const getAllConfigs = useCallback( async () => {
    try {
      const configs = await service.getAllConfig();
      if (configs) {
        const newConfig = configs.data.map((d) => {
          return {
            ...d,
            data: JSON.parse(d.data),
          };
        });
        setGlobalData(newConfig);
      }
    } catch (e) {
      ErrorHandler.handle(e);
    }
  },[service]);
  useEffect(() => {
    getAllConfigs();
  }, [getAllConfigs]);

  const cancel = useCallback(() => {
    setGlobalData(null);
    getAllConfigs();
  },[getAllConfigs]);
  return (
    <div className={"card w-full bg-base-100"} style={{ paddingBottom: 20 }}>
      <PageHeader
        title={"Configurations"}
        subTitle={"Gestion des configurations"}
        icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
      >
        {" "}
      </PageHeader>
      <>
        <div className="flex flex-col gap-6">
          {globalData !== null &&
            globalData?.length > 0 &&
            globalData.map((d, i) => (
              <Config key={i} data={d} setGlobalData={setGlobalData} />
            ))}
        </div>
        <div className="flex gap-3 justify-end mt-8">
          <Controls.Button variant="outlined" text="Annuler" onClick={()=> cancel()}/> 
          <Controls.Button variant="contained" text='Valider' onClick={valideEmailConfig} />
        </div>
      </>
    </div>
  );
}
